import * as React from 'react';

import { SEOHelmet } from 'components/SEO/SEOHelmet';

import LoadingScreen from 'components/ScreenOverlays/LoadingScreen/LoadingScreen';
import InterfaceOverlay from 'components/InterfaceOverlay/InterfaceOverlay';
//import PagePositionBar from 'components/InterfaceOverlay/PagePositionBar/PagePositionBar';
import GridGuides from 'components/ScreenOverlays/GridGuides/GridGuides';
import Header from 'components/Header/Header';
import CookieModal from 'components/ScreenOverlays/CookieModal/CookieModal';
import { DisableScroll } from 'components/DisableScroll/DisableScroll';

export const Layout: React.FC<{}> = props => {
  return (
    <div className='container-fluid'>
      <SEOHelmet
        schema="WebPage"
        title="We create extraordinary experiences to help tell your brand's story"
        description="We create extraordinary experiences to help tell your brand's story"
        contentType="product"
      />
      {/*<GridGuides />*/}
      {/*<PagePositionBar />*/}
      <LoadingScreen />
      <Header />
      <CookieModal />
      <InterfaceOverlay backgroundShade={'dark'} mainSiteOverlay={true} />
      <DisableScroll />
      {props.children}
    </div>
  );
};

//@ts-ignore
const isDevBuild = process.argv.indexOf('--env.prod') < 0;
console.log('isDevBuild: ' + isDevBuild);

if (isDevBuild) {
  setTimeout(() => {
    const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
    whyDidYouRender(React);
  }, 500);
}
