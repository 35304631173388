import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useSpring, a, SpringConfig, to } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

import * as fromUserInterface from 'store/UserInterface';

import { useLatest } from 'utils/Index';
import classNames from 'classnames';

interface IProps {
  backgroundImage: string;
  persist: boolean;
  animateOut: boolean;
}

const LogomarkSlideComponent: React.FC<IProps> = ({ animateOut, persist, backgroundImage, ...props }) => {
  const interfaceInitialised = useSelector(fromUserInterface.getInitialised);
  const dispatch = useDispatch();
  const markInterfaceAsInitialised = React.useCallback(() => dispatch(fromUserInterface.actionCreators.markAsInitialised()), [dispatch]);

  const [animate, setAnimate] = React.useState(false);
  const [active, setActive] = React.useState(true);

  React.useEffect(() => {
    setAnimate(true);
  }, []);

  const latestPersist = useLatest(persist);

  const removeSlide = () => {
    if (!interfaceInitialised) markInterfaceAsInitialised();
    if (!latestPersist) {
      //console.log('no persist');
      setActive(false);
    } else {
      //console.log('persist');
    }
  };

  const commonSpringConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 170,
    friction: 27,
  };

  const opacitySpringConfig: SpringConfig = {
    ...commonSpringConfig,
    tension: 200,
    friction: 36,
  };

  const animateInSpringConfig: SpringConfig = {
    ...commonSpringConfig,
    tension: 120,
    friction: 36,
  };

  const animateOutSpringConfig: SpringConfig = {
    ...commonSpringConfig,
    tension: 170,
    friction: 28,
  };
  const [animateInSpring, setAnimateInSpring] = useSpring(() => ({
    active: 100,
    config: animateInSpringConfig,
    onRest: () => removeSlide(),
  }));
  const [opacitySpring, setOpacitySpring] = useSpring(() => ({
    opacity: 1,
    config: opacitySpringConfig,
  }));

  React.useEffect(() => {
    setAnimateInSpring({ active: animate ? 0 : 100, immediate: !interfaceInitialised ? true : false });
    if (!interfaceInitialised)
      setOpacitySpring({ from: { opacity: 0 }, opacity: 1 });
  }, [animate]);

  const [animateOutSpring, setAnimateOutSpring] = useSpring(() => ({
    active: 1,
    config: animateOutSpringConfig,
  }));

  React.useEffect(() => {
    setAnimateOutSpring({ active: animateOut && (animateInSpring.active.getValue() < 10) ? 0.85 : 1 });
  }, [animateOut, animateInSpring]);

  const backgroundOverlayStyles = {
    opacity: opacitySpring.opacity,
    backgroundColor: (backgroundImage == null ? '#fff' : null),
    clipPath: animateInSpring.active.to(y => `polygon(100% ${y}%, 100% 100%, 0% 100%, 0% ${y}%)`),
    willChange: `clip-path`,
  };

  const backgroundImageStyles = {
    transform: to(
      [animateInSpring.active, animateOutSpring.active],
      (pos, scale) => `translateY(${((pos / 100) * 6) - 3}%) scale(${scale}, ${scale})`,
    ),
    //willChange: `transform`,
  };

  if (active)
    return (
      <a.div
        className={classNames(
          'backgroundOverlay',
          { active: animate },
        )}
        style={backgroundOverlayStyles}
      >
        <a.img
          src={backgroundImage}
          alt='logomark background'
          style={backgroundImageStyles}
        />
      </a.div>
    );
  else
    return null;
};

export const LogomarkSlide = LogomarkSlideComponent;
