import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

//const seoImages = {
//    openGraph: [
//        'open-graph-blue.png',
//        'open-graph-green.png',
//        'open-graph-red.png',
//        'open-graph-yellow.png',
//    ],
//    twitter: [
//        'twitter-card-blue.png',
//        'twitter-card-green.png',
//        'twitter-card-red.png',
//        'twitter-card-yellow.png',
//    ],
//    google: [
//        'google-blue.png',
//        'google-green.png',
//        'google-red.png',
//        'google-yellow.png',
//    ],
//};

const seoImages = {
  openGraph: 'open-graph-blue.png',
  twitter: 'twitter-card-blue.png',
  google: 'google-blue.png',
};

const siteName = 'Qore';

const seoImageURL = file => `https://s3.amazonaws.com/tmc-site-assets/graphics/${file}`;
//const seoURL = path => Meteor.absoluteUrl(path);
const seoURL = path => 'https://www.qorestudio.com' + path;

const getMetaTags = ({
  title, description, url, contentType, published, updated, category, tags, twitter,
}) => {
  const metaTags = [
    { charset: 'utf-8' },

    { itemprop: 'name', content: title },
    { itemprop: 'description', content: description },
    //{ itemprop: 'image', content: seoImageURL(_.sample(seoImages.google)) },
    { itemprop: 'image', content: seoImageURL(seoImages.google) },

    { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
    { name: 'application-name', content: siteName },
    { name: 'author', content: siteName },
    { name: 'theme-color', content: '#161616' },
    { name: 'msapplication-TileColor', content: '#161616' },
    { name: 'msapplication-TileImage', content: '/ms-icon-144x144.png' },
    { name: 'description', content: description },
    { name: 'google-site-verification', content: 'FaWrracvvD6ReRwmq_KBjq3RMnd42fRaVn5s3nd2cGI'},

    //{ name: 'twitter:card', content: 'summary_large_image' },
    //{ name: 'twitter:site', content: '@themeteorchef' },
    //{ name: 'twitter:title', content: `${title} | ${siteName}` },
    //{ name: 'twitter:description', content: description },
    //{ name: 'twitter:creator', content: twitter || '@themeteorchef' },
    ////{ name: 'twitter:image:src', content: seoImageURL(_.sample(seoImages.twitter)) },
    //{ name: 'twitter:image:src', content: seoImageURL(seoImages.twitter) },

    //{ name: 'og:title', content: `${title} | ${siteName}` },
    //{ name: 'og:type', content: contentType },
    //{ name: 'og:url', content: url },
    ////{ name: 'og:image', content: seoImageURL(_.sample(seoImages.openGraph)) },
    //{ name: 'og:image', content: seoImageURL(seoImages.openGraph) },
    //{ name: 'og:description', content: description },
    //{ name: 'og:site_name', content: siteName },

    //{ name: 'fb:app_id', content: '<FB App ID>' },
  ];

  if (published) metaTags.push({ name: 'article:published_time', content: published });
  if (updated) metaTags.push({ name: 'article:modified_time', content: updated });
  if (category) metaTags.push({ name: 'article:section', content: category });
  if (tags) {
    metaTags.push({ name: 'article:tag', content: tags });
    metaTags.push({ name: 'keywords', content: tags });
  }

  return metaTags;
};

export interface ITags {
  imageName: string;
}

interface ISpecificProps {
  schema?: string;
  title?: string;
  description?: string;
  contentType?: string;
  published?: string;
  updated?: string;
  category?: string;
  tags?: string[];
  twitter?: string;
}

type IProps = ISpecificProps;

const SEOHelmetComponent: React.FC<IProps> = ({ schema, title, description, contentType, published, updated, category, tags, twitter, ...props }) => {
  const location = useLocation();
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
        itemscope: undefined,
        itemtype: `https://schema.org/${schema}`,
      }}
      defaultTitle={siteName}
      titleTemplate={"%s - " + siteName}
      title={title}
      link={[
        { rel: 'canonical', href: seoURL(location.pathname) },
        { rel: 'apple-touch-icon', sizes: '57x57', href: '/apple-icon-57x57.png' },
        { rel: 'apple-touch-icon', sizes: '60x60', href: '/apple-icon-60x60.png' },
        { rel: 'apple-touch-icon', sizes: '72x72', href: '/apple-icon-72x72.png' },
        { rel: 'apple-touch-icon', sizes: '76x76', href: '/apple-icon-76x76.png' },
        { rel: 'apple-touch-icon', sizes: '114x114', href: '/apple-icon-114x114.png' },
        { rel: 'apple-touch-icon', sizes: '120x120', href: '/apple-icon-120x120.png' },
        { rel: 'apple-touch-icon', sizes: '144x144', href: '/apple-icon-144x144.png' },
        { rel: 'apple-touch-icon', sizes: '152x152', href: '/apple-icon-152x152.png' },
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-icon-180x180.png' },
        { rel: 'icon', type: 'image/png', sizes: '192x192', href: '/android-icon-192x192.png' },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
        { rel: 'icon', type: 'image/png', sizes: '96x96', href: '/favicon-96x96.png' },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
        { rel: 'manifest', href: '/manifest.json' },
        { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#5bbad5' },
      ]}
      meta={getMetaTags({
        title,
        description,
        contentType,
        url: seoURL(location.pathname),
        published,
        updated,
        category,
        tags,
        twitter,
      })}
    />
  );
};

export const SEOHelmet = SEOHelmetComponent;
