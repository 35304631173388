import * as React from 'react';

import { useSpring, a, to as springTo, SpringConfig, SpringValue } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

import { useAnimateInTrail } from 'hooks/useAnimateInTrail';

import BlockLink from 'components/Links/BlockLink/BlockLink';

import classNames from 'classnames';

import { useTimeout } from 'utils/Index';

import { preloadRouteComponent } from 'app/routes';

export type IBackgroundShade = 'light' | 'dark';
export type IAlign = 'left' | 'right';

interface IOwnSpecificProps {
  to?: string;
  backgroundShade?: 'light' | 'dark';
  animateIn?: number;
  animationReady?: boolean;
  onClick?: () => void;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  title: string;
  styles?: object;
}

type IOwnProps = IOwnSpecificProps;

type IProps = IOwnProps;

const TriggerLink: React.FC<IProps> = React.forwardRef(({ animationReady, animateIn, styles, backgroundShade, title, to, ...props }, ref) => {
  const myRef = React.useRef();
  React.useImperativeHandle(ref, () => ({}));

  const [clicked, setClicked] = React.useState(false);
  const [hovering, setHovering] = React.useState(false);

  const [delay, setDelay] = React.useState(500);
  const [triggerTimeout, setTriggerTimeout] = React.useState(false);

  useTimeout(() => {
    setClicked(false);
  }, triggerTimeout ? delay : null);

  const handleClick = () => {
    if (props.onClick)
      props.onClick();
    setTriggerTimeout(false);
    setClicked(true);
    setTriggerTimeout(true);
  };

  const handleMouseEnter = () => {
    if (!hovering)
      setHovering(true);
    preloadRouteComponent(to);
    if (props.onMouseEnter)
      props.onMouseEnter();
  };

  const handleMouseLeave = () => {
    if (hovering || clicked) {
      setHovering(false);
      setClicked(false);
    }
    if (props.onMouseLeave)
      props.onMouseLeave();
  };

  const animateInTrail = useAnimateInTrail(animateIn > 0 && animationReady, 2);

  const springConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 340,
    friction: 36,
  };

  const [activeSpring, setActiveSpring] = useSpring(() => ({
    x: 0,
    config: springConfig,
  }));

  React.useEffect(() => {
    setActiveSpring({ x: hovering ? 1 : 0 });
  }, [hovering]);

  const largeTriggerStyles = {
    width: springTo(
      [animateInTrail[1].active, activeSpring.x],
      (an, x) => `calc((${x} * (100% - 50px)) + 46px + (${an} * 10px))`,
    ),
    height: springTo(
      [animateInTrail[1].active, activeSpring.x],
      (an, x) => `calc((${x} * (100% - 50px)) + 46px + (${an} * 10px))`,
    ),
    opacity: animateInTrail[1].active,
  };

  const smallTriggerStyles = {
    width: springTo(
      [animateInTrail[0].active, activeSpring.x],
      (an, x) => `calc((${x} * (100% - 8px)) + 4px + (${an} * 4px))`,
    ),
    height: springTo(
      [animateInTrail[0].active, activeSpring.x],
      (an, x) => `calc((${x} * (100% - 8px)) + 4px + (${an} * 4px))`,
    ),
    opacity: animateInTrail[0].active,
  };

  const labelStyles = {
    opacity: activeSpring.x,
  };

  return (
    <div ref={myRef} className='triggerContainer' style={styles}>
      <a.div
        className={classNames(
          'triggerLinkWrapper',
          'backgroundShade-' + backgroundShade,
          { active: activeSpring.x },
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className='label'>{title}</div>
        <a.div
          className='largeTrigger'
          style={largeTriggerStyles}
        />
        <a.div className='smallTrigger' style={smallTriggerStyles}>
          <BlockLink to={to} title={title}>
            <a.div className='label' style={labelStyles}>
              {title}
            </a.div>
          </BlockLink>
          <div className='actualTrigger' />
        </a.div>
      </a.div>
    </div>
  );
});

export default a(TriggerLink);

