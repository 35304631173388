import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';

import { useSpring, a, SpringConfig } from 'react-spring';
import { useGesture } from 'react-use-gesture';

import classNames from 'classnames';

import * as fromMainNavigation from 'store/MainNavigation';

import SimpleLink from 'components/Links/SimpleLink/SimpleLink';

import { useAnimateInTrail } from 'hooks/useAnimateInTrail';
import { initialOffset, defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';
import { mapObject, getPixelRatio } from 'utils/Index';

export interface INavAnimationOpacity {
  opacity: number;
  variance: number;
  topBoundaryDistance: number;
  bottomBoundaryDistance: number;
  animationFrameSpeed: number;
  animationStartFrame: number;
  targetOpacity: number;
  totalOpacity: number;
}

export interface INavAnimationItem {
  type: string;
  x: number;
  y: number;
  changeX: number;
  changeY: number;
  angle: number;
  section: INavAnimationOpacity[];
  totalOpacity: number;
}

const NavMenu: React.FC<{}> = props => {
  const location = useLocation();

  const [animateIn, setAnimateIn] = React.useState(false);

  const navMenu = useSelector(fromMainNavigation.getNavMenuProps);
  const areasById = useSelector(fromMainNavigation.getAreasById);

  const dispatch = useDispatch();

  const markNavMenuLoadedAction = React.useCallback(() => dispatch(fromMainNavigation.actionCreators.markNavMenuLoadedAction()), [dispatch]);

  const hideNavMenuAction = React.useCallback(
    () => dispatch(fromMainNavigation.actionCreators.hideNavMenuAction())
    , [dispatch]);

  const handleMainNavClickAction = React.useCallback(
    (linkId: number) => dispatch(fromMainNavigation.actionCreators.handleMainNavClickAction(linkId))
    , [dispatch]);

  const navMenuRef = React.useRef<HTMLDivElement>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const [navMenuWidth, setNavMenuWidth] = React.useState(400);
  const [navMenuHeight, setNavMenuHeight] = React.useState(400);

  const updateMenuDimensions = () => {
    const navMenuProps = navMenuRef.current.getBoundingClientRect();
    setNavMenuHeight(navMenuProps.height);
    setNavMenuWidth(navMenuProps.width);
  };

  React.useLayoutEffect(() => {    
    markNavMenuLoadedAction();
    updateMenuDimensions();

    // Setup event listeners on initial mount to keep the  dimensions of the menu updated
    window.addEventListener("resize", updateMenuDimensions);

    return () => {
      window.removeEventListener("resize", updateMenuDimensions);
    };
  }, []);

  const animateCursorSpringConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 170,
  };
  const [cursorPosition, setCursorPosition] = useSpring(() => ({ x: 0, y: 0, springConfig: animateCursorSpringConfig }));
  const bind = useGesture({
    onMove: state => handleMove(state),     // fires on mouse move over the element
  });

  const handleMove = state => {
    const { xy: [x, y] } = state;
    setCursorPosition({ x, y });
  };

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const ratio = getPixelRatio(ctx);

    canvas.width = navMenuWidth * ratio;
    canvas.height = navMenuHeight * ratio;
    canvas.style.width = `${navMenuWidth}px`;
    canvas.style.height = `${navMenuHeight}px`;

    function drawLine(x, y, width, height, degrees, opacity) {

      // first save the untranslated/unrotated context
      ctx.save();

      ctx.beginPath();
      // move the rotation point to the center of the rect
      ctx.translate(x, y);
      //ctx.translate(x + width / 2, y + height / 2);
      // rotate the rect
      const finalAngle = degrees + 90;
      ctx.rotate(finalAngle * Math.PI / 180);

      // draw the rect on the transformed context
      // Note: after transforming [0,0] is visually [x,y]
      //       so the rect needs to be offset accordingly when drawn
      ctx.rect(-width / 2, -height / 2, width, height);

      ctx.fillStyle = `rgba(225,225,225,${opacity})`;
      ctx.fill();

      // restore the context to its untranslated/unrotated state
      ctx.restore();

    }

    const convertRadToDeg = rad => {
      return rad * 180 / Math.PI;
    };

    let requestId;
    const render = () => {
      const margin = 10;

      const cursorX = cursorPosition.x.getValue() * ratio;
      const cursorY = cursorPosition.y.getValue() * ratio;

      const lineGap = 80;
      const lineLength = 30;
      const lineWidth = 2;

      const boardWidth = canvas.width - (margin * 2);
      const boardHeight = canvas.height - (margin * 2);
      const boardMaxDistance = Math.sqrt(Math.pow(Math.abs(boardWidth), 2) + Math.pow(Math.abs(boardHeight), 2));

      const numberOfColumns = Math.floor(boardWidth / lineGap);
      const numberOfRows = Math.floor(boardHeight / lineGap);

      const cursorRange = boardWidth;

      const gridWidth = (numberOfColumns - 1) * lineGap;
      const gridHeight = (numberOfRows - 1) * lineGap;

      const firstColumnX = margin + ((boardWidth - gridWidth) / 2);
      const firstRowY = margin + ((boardHeight - gridHeight) / 2);

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (let r = 0; r < numberOfRows; r++) {
        for (let c = 0; c < numberOfColumns; c++) {
          const lineX = firstColumnX + (c * lineGap);
          const lineY = firstRowY + (r * lineGap);

          const cursorXDistance = cursorX - lineX;
          const cursorYDistance = cursorY - lineY;
          const cursorLineDistance = Math.sqrt(Math.pow(Math.abs(cursorXDistance), 2) + Math.pow(Math.abs(cursorYDistance), 2));

          const lineAngle = convertRadToDeg(Math.atan2(cursorYDistance, cursorXDistance)) + (Math.random() * cursorLineDistance / boardMaxDistance * 30);

          const scaledOpacity = (cursorLineDistance < cursorRange ? cursorLineDistance / cursorRange : 1);
          const lineOpacity = (0.25) * scaledOpacity;

          drawLine(lineX, lineY, lineLength, lineWidth, lineAngle, lineOpacity);
        }
      }


      requestId = requestAnimationFrame(render);
    };

    render();

    return () => {
      cancelAnimationFrame(requestId);
    };

  });

  React.useEffect(() => {
    if (navMenu.open && !animateIn) {
      updateMenuDimensions();
      setAnimateIn(true);
    }
    else if (!navMenu.open && animateIn) {
      setAnimateIn(false);
    }
  }, [navMenu.open, animateIn]);

  const openMenuSpringConfig: SpringConfig = {
    mass: 1,
    tension: 250,
    friction: 36,
    clamp: true,
    precision: 0.01,
    velocity: 0,
    //duration: undefined,
    easing: t => t,
  };
  const [openMenuSpring, setOpenMenuSpring] = useSpring(() => ({ x: 0, config: openMenuSpringConfig }));

  React.useEffect(() => {
    setOpenMenuSpring({ x: navMenu.open ? 1 : 0 });
  }, [navMenu.open]);

  const peekMenuSpringConfig: SpringConfig = {
    ...openMenuSpringConfig,
    tension: 500,
  };
  const [peekMenuSpring, setPeekMenuSpring] = useSpring(() => ({ x: 0, config: peekMenuSpringConfig }));

  React.useEffect(() => {
    setPeekMenuSpring({ x: navMenu.peek ? 1 : 0 });
  }, [navMenu.peek]);

  const contentOverlaySpringConfig: SpringConfig = peekMenuSpringConfig;

  const [contentOverlaySpring, setContentOverlaySpring] = useSpring(() => ({ x: 0, config: contentOverlaySpringConfig }));

  React.useEffect(() => {
    setContentOverlaySpring({ x: navMenu.peek ? 0.8 : (navMenu.open ? 1 : 0) });
  }, [navMenu.open, navMenu.peek]);

  const navMenuPeekStyles = {
    transform: peekMenuSpring.x.to(x => `translateY(calc((-100%) + (${x} * 100%)))`),
  };

  const globalNavMenuStyles = {
    opacity: 1,
    transform: openMenuSpring.x.to(x => `translateY(calc((-100%) + (${x} * 100%)))`),
  };

  const globalNavOverlayStyles = {
    opacity: contentOverlaySpring.x.to(x => 0.7 * x),
    //opacity: 1 * contentOverlay,
    display: contentOverlaySpring.x.to(x => x === 0 ? 'none' : 'block'),
    cursor: openMenuSpring.x.to(x => x === 0 ? 'default' : 'pointer'),
  };

  const animateInTrail = useAnimateInTrail(animateIn, Object.keys(areasById).length + 3);

  const navLinkStyles = (index: number) => ({
    opacity: animateInTrail[index].active,
    transform: animateInTrail[index].active.to(x => `translateY(calc(${initialOffset(x, 20)}))`),
  });

  const detailsStyles = (index: number) => ({
    opacity: animateInTrail[index].active,
    transform: animateInTrail[index].active.to(x => `translateY(calc(${initialOffset(x, 20)}))`),
  });

  const [currentPath, setCurrentPath] = React.useState(null);

  React.useEffect(() => {
    console.log(location);
    mapObject(areasById, (navigationItemId, navigationItem) => {
      const match = matchPath(location.pathname, {
        path: navigationItem.url,
        exact: false,
        strict: false,
      });
      if (match != null)
        setCurrentPath(navigationItemId);
    });
  }, [location, areasById]);

  return (
    <nav className='navMenuWrapper'>
      <a.div className='navMenuPeekWrapper' style={navMenuPeekStyles} />
      <a.div
        {...bind()}
        ref={navMenuRef}
        className={classNames(
          'global-nav-menu',
          { navMenuOpen: navMenu.open },
        )}
        style={globalNavMenuStyles}
      >
        <canvas ref={canvasRef} />
        <ul className='global-nav-list'>
          {mapObject(areasById, (navigationItemId, navigationItem) => {
            const animationIndex = navigationItemId * 1;
            return <a.li
              key={navigationItemId}
              style={navLinkStyles(animationIndex)}
            >
              <SimpleLink
                to={navigationItem.url}
                id={navigationItemId}
                onClick={() => handleMainNavClickAction(navigationItemId)}
                size='large'
                title={navigationItem.title}
                isActive={currentPath === navigationItemId}
              >
                {navigationItem.title}
              </SimpleLink>
            </a.li>;
          })}
        </ul>
        <div className='details-bar'>
          <a.div
            className='details-wrapper emailAddress'
            style={detailsStyles(Object.keys(areasById).length + 2)}
          >
            <SimpleLink to='mailto:hey@qorestudio.com' type='classic' size='medium' title='hey@qorestudio.com'>
              hey@qorestudio.com
            </SimpleLink>
          </a.div>
          <a.div
            className='details-wrapper phoneNumber'
            style={detailsStyles(Object.keys(areasById).length + 1)}
          >
            <SimpleLink to='tel:07704124123' type='classic' size='medium' title='0770 412 4123'>
              0770 412 4123
            </SimpleLink>
          </a.div>
          <a.div
            className='details-wrapper socialIcons'
            style={detailsStyles(Object.keys(areasById).length + 2)}
          >
            <div>Colchester, UK</div>
            {/*<SimpleLink to='https://www.facebook.com' type='classic' newWindow size='medium' title='Facebook'>
                        Facebook
                      </SimpleLink>*/}
          </a.div>
        </div>
      </a.div>
      <a.div
        className={classNames(
          'global-nav-overlay',
          { navMenuOpen: openMenuSpring.x.to(x => x) },
        )}
        onClick={hideNavMenuAction}
        style={globalNavOverlayStyles}
      />
    </nav>
  );
};

export default NavMenu;
