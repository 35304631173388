import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

let intersectionObserver;
if (typeof window !== 'undefined') {
  // tslint:disable-next-line
  intersectionObserver = require("intersection-observer");
}

import { useTransition, a } from 'react-spring';

// @ts-ignore
//import { useMediaQuery } from 'react-responsive';

//import { LgScreenHidden } from 'react-responsive-redux';

// @ts-ignore
//import { useMediaQuery } from 'react-responsive';


import path from 'path';
import Loadable from 'react-loadable';

//import MediaQuery from 'react-responsive';
//import { MediaQuery, Context as ResponsiveContext } from 'react-responsive';
import { ParallaxProvider } from 'react-scroll-parallax';

import { Layout } from 'views/Shared/Layout';

import * as fromUserInterface from 'store/UserInterface';

import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';

import { useLatest, hashCode } from 'utils/Index';
//import { IProps } from './components/InterfaceOverlay/ContactChatModal/ModalMessage';

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ 'views/Home/Home'),
  loading: () => <div className='loadingNewComponent' />,
  delay: 200,
  //serverSideRequirePath: path.join(__dirname, 'views/Home/Home'),
  modules: ['views/Home/Home'],
  webpack: () => [require.resolveWeak('views/Home/Home')],
});

const Work = Loadable({
  loader: () => import(/* webpackChunkName: "Work" */ 'views/Work/Work'),
  loading: () => <div className='loadingNewComponent' />,
  delay: 200,
  //serverSideRequirePath: path.join(__dirname, 'views/Work/Work'),
  modules: ['views/Work/Work'],
  webpack: () => [require.resolveWeak('views/Work/Work')],
});

const WorkDetails = Loadable({
  loader: () => import(/* webpackChunkName: "WorkDetails" */ 'views/Work/WorkDetails'),
  loading: () => <div className='loadingNewComponent' />,
  delay: 200,
  //serverSideRequirePath: path.join(__dirname, 'views/Work/WorkDetails'),
  modules: ['views/Work/WorkDetails'],
  webpack: () => [require.resolveWeak('views/Work/WorkDetails')],
});

const OurStory = Loadable({
  loader: () => import(/* webpackChunkName: "OurStory" */ 'views/OurStory/OurStory'),
  loading: () => <div className='loadingNewComponent' />,
  delay: 200,
  //serverSideRequirePath: path.join(__dirname, 'views/OurStory/OurStory'),
  modules: ['views/OurStory/OurStory'],
  webpack: () => [require.resolveWeak('views/OurStory/OurStory')],
});

const Services = Loadable({
  loader: () => import(/* webpackChunkName: "Services" */ 'views/Services/Services'),
  loading: () => <div className='loadingNewComponent' />,
  delay: 200,
  //serverSideRequirePath: path.join(__dirname, 'views/Services/Services'),
  modules: ['views/Services/Services'],
  webpack: () => [require.resolveWeak('views/Services/Services')],
});

const Contact = Loadable({
  loader: () => import(/* webpackChunkName: "Contact" */ 'views/Contact/Contact'),
  loading: () => <div className='loadingNewComponent' />,
  delay: 200,
  //serverSideRequirePath: path.join(__dirname, 'views/Contact/Contact'),
  modules: ['views/Contact/Contact'],
  webpack: () => [require.resolveWeak('views/Contact/Contact')],
});

const NotFound = Loadable({
  loader: () => import(/* webpackChunkName: "NotFound" */ 'views/Error/NotFound'),
  loading: () => <div className='loadingNewComponent' />,
  delay: 200,
  //serverSideRequirePath: path.join(__dirname, 'views/Error/NotFound'),
  modules: ['views/Error/NotFound'],
  webpack: () => [require.resolveWeak('views/Error/NotFound')],
});

const firstChild = props => {
  const childrenArray = React.Children.toArray(props.children);
  return childrenArray[0] || null;
};

const routesArray = [
  {
    exact: true,
    path: '/work',
    component: Work,
    routes: [
      {
        exact: true,
        path: '/:projectName',
        component: WorkDetails,
      },
    ],
  },
  {
    exact: true,
    path: '/our-story',
    component: OurStory,
  },
  {
    exact: true,
    path: '/services',
    component: Services,
  },
  {
    exact: true,
    path: '/contact',
    component: Contact,
  },
  {
    exact: true,
    path: '/',
    component: Home,
  },
  {
    exact: false,
    path: '/home',
    component: Home,
  },
  {
    exact: false,
    path: null,
    component: NotFound,
  },
] as IRoute[];

export const preloadRouteComponent = (urlPath: string) => {
  routesArray.map((route, key) => {
    findRoute(null, route, urlPath);
  });
};

//const ShowDesktopSite = ({ children }) => {
//  const isMobile = useMediaQuery({ maxWidth: 1200 });
//  return isMobile ? children : null;
//};

const findRoute = (baseRoute: string, route, urlPath: string) => {
  let routePath: string = route.path;
  if (baseRoute != null)
    routePath = baseRoute + routePath;

  if (route.routes != null)
    route.routes.map((childRoute: IRoute, childKey) => {
      findRoute(routePath, childRoute, urlPath);
    });

  let matchedPath: boolean = false;

  if (routePath === urlPath) {
    matchedPath = true;
  } else if (routePath && route.path) {
    if ((urlPath.indexOf('/work/') > -1) && (routePath.indexOf('/work') > -1) && (route.path.indexOf(':') > -1)) {
      matchedPath = true;
    }
  }

  if (matchedPath)
    route.component.preload();

};

interface IRoute {
  exact: boolean;
  path: string;
  component: React.ComponentType<any>;
  routes?: IRoute[];
}

export const RoutesComponent: React.FC<{}> = () => {
  const history = useHistory();
  const location = useLocation();
  const latestHistory = useLatest(history);
  const loadingScreens = useSelector(fromUserInterface.getLoadingScreens);
  const dispatch = useDispatch();
  const triggerNewLoadingScreen = React.useCallback((id: string, recordPageScroll: boolean, action: string) => dispatch(fromUserInterface.actionCreators.triggerNewLoadingScreen(id, recordPageScroll, action)), [dispatch]);
  const hideLoadingScreen = React.useCallback((id: string, modifyScroll: boolean) => dispatch(fromUserInterface.actionCreators.hideLoadingScreen(id, modifyScroll, true)), [dispatch]);

  const createRoute = (baseRoute: string = '', route: IRoute, key) => {
    let newRoute = null;

    let newPath = route.path;
    if (baseRoute != null)
      newPath = baseRoute + newPath;

    const uniqueKey = hashCode(newPath != null ? newPath : 'nefiunwklfnvesdlknvcuoenwvoneswinvoiewnlkndvkas');

    if (route.hasOwnProperty('routes')) {
      newRoute = route.routes.map((childRoute: IRoute, childKey) => {
        return createRoute(route.path, childRoute, uniqueKey);
      });
    }

    const RouteComponent = route.component;

    newRoute = [
      <Route exact={route.exact} path={newPath} key={uniqueKey}><RouteComponent /></Route>,
      newRoute,
    ];
    return newRoute;
  };

  const breakpoints = {
    //desktop: '(min-width: 1025px)',
    //tablet: '(min-width: 768px) and(max - width: 1024px)',
    //phone: '(max-width: 767px)',
    desktop: '(min-width: 1280px)',
    mobile: '(max-width: 1279px)',
  };

  const [routeComponents] = React.useState(routesArray.map((route: IRoute, key) => createRoute(null, route, key)));

  const latestLoadingScreens = useLatest(loadingScreens);

  const transitionStarting = startValues => {
    if (startValues.pathname === latestHistory.current.location.pathname) {
      const screenId = hashCode(startValues.pathname).toString();

      let recordPageScroll = true;
      if (Object.keys(latestLoadingScreens.current).length !== 0)
        recordPageScroll = false;

      triggerNewLoadingScreen(screenId, recordPageScroll, latestHistory.current.action);
    }
  };

  const transitionEnded = endValues => {
    let modifyScroll = true;
    const screenId = hashCode(endValues.pathname).toString();

    const visibleScreens = [];
    Object.keys(latestLoadingScreens.current).map(id => {
      if (latestLoadingScreens.current[id].visible === true)
        visibleScreens.push(id);
    });
    if (visibleScreens.length > 1)
      modifyScroll = false;

    hideLoadingScreen(screenId, modifyScroll);
  };

  const routeTransitions = useTransition(location, newLocation => newLocation.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    unique: true,
    onStart: startValues => transitionStarting(startValues),
    onRest: endValues => transitionEnded(endValues),
  });

  const routeTransitionStyles = style => ({
    //...style,
    opacity: style.opacity.to(x => x === 0 ? 0 : x === 1 ? 1 : null),
    position: 'absolute' as 'absolute',
    top: 0,
    width: '100%',
    willChange: `opacity`,
  });

  return (
    <ErrorBoundary>
      <Layout>
        <ParallaxProvider>
          {routeTransitions.map(({ item, props: style, key }) => (
            <a.div className='routeContainer' key={key} style={routeTransitionStyles(style)}>
              <Switch location={item}>
                {routeComponents}
              </Switch>
            </a.div>
          ))}
        </ParallaxProvider>
      </Layout>
    </ErrorBoundary>
  );
};

export const Routes = RoutesComponent;
