import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Trigger, ITriggerProps } from 'components/InterfaceOverlay/InterfaceTrigger/Trigger';

import { IApplicationState } from 'store/index';
import * as fromInterfaceOverlay from 'store/InterfaceOverlay';

type IOwnProps = ITriggerProps;

interface IStateProps {
  active: boolean;
}

type IDispatchProps = typeof fromInterfaceOverlay.actionCreators;

type IProps = IStateProps & IDispatchProps & IOwnProps;

const BackToWorkTriggerComponent: React.FC<IProps> = ({ active, ...props }) => {
  const handleMouseEnter = () => {
    if (!active)
      props.enterBackToWorkTriggerAction();
  };

  const handleMouseLeave = () => {
    if (active)
      props.leaveBackToWorkTriggerAction();
  };

  return (
    <Trigger
      active={active}
      title='Back to work'
      url='/work'
      align={'left'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    />
  );
};

// Selects which state properties are merged into the component's props
const mapStateToProps = (state: IApplicationState, ownProps: IOwnProps): IStateProps => ({
  active: fromInterfaceOverlay.getBackToWorkTrigger(state),
});

// Selects which action creators are merged into the component's props
const mapDispatchToProps: IDispatchProps = fromInterfaceOverlay.actionCreators;

// Wire up the React component to the Redux store
export const BackToWorkTrigger = withRouter<any, any>(connect<IStateProps, IDispatchProps, IOwnProps>
  (mapStateToProps, mapDispatchToProps)(BackToWorkTriggerComponent));
