import * as React from 'react';
import { Link } from 'react-router-dom';

import { useTimeout } from 'utils/Index';

import { preloadRouteComponent } from 'app/routes';

export interface IOwnSpecificProps {
  to?: string;
  type?: string;
  id?: string;
  isActive?: boolean;
  onClick?: (e?: any) => void;
  onMouseEnter?: (e?: any) => void;
  onMouseLeave?: (e?: any) => void;
  newWindow?: boolean;
  title: string;
}

type IOwnProps = IOwnSpecificProps;

type IProps = IOwnProps;

const BlockLink: React.FC<IProps> = ({
  to = null,
  type = '',
  id = '',
  isActive = false,
  newWindow = false,
  title = '',
  ...props
}) => {
  const [clicked, setClicked] = React.useState(false);
  const [hovering, setHovering] = React.useState(false);

  const [delay, setDelay] = React.useState(500);
  const [triggerTimeout, setTriggerTimeout] = React.useState(false);

  useTimeout(() => {
    setClicked(false);
  }, triggerTimeout ? delay : null);

  const handleClick = () => {
    if (props.onClick)
      props.onClick();
    setTriggerTimeout(false);
    setClicked(true);
    setTriggerTimeout(true);
  };

  const handleMouseEnter = () => {
    if (!hovering)
      setHovering(true);
    if (to != null)
      preloadRouteComponent(to);
    if (props.onMouseEnter)
      props.onMouseEnter();
  };

  const handleMouseLeave = () => {
    if (hovering || clicked) {
      setHovering(false);
      setClicked(false);
    }
    if (props.onMouseLeave)
      props.onMouseLeave();
  };

  const inputProps: { [k: string]: any } = {
    id,
    onClick: handleClick,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    target: (newWindow ? '_blank' : null),
    rel: (newWindow ? 'noopener' : null),
    title,
  };

  if (to == null)
    return (
      <div {...inputProps} >
        {props.children}
      </div>
    );
  else if (type === 'classic')
    return (
      <a href={to} {...inputProps}>
        {props.children}
      </a>
    );
  else
    return (
      <Link to={to} {...inputProps} >
        {props.children}
      </Link>
    );

};

export default BlockLink;
