import * as React from 'react';
import { useSelector } from 'react-redux';

import { useSpring, a, SpringConfig } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

import { LogomarkSlide } from './LogomarkSlide';

import * as fromProjects from 'store/Projects';
import * as fromUserInterface from 'store/UserInterface';

import { useTimeout, useInterval, useLatest } from 'utils/Index';

interface IOwnSpecificProps {
  finalBackgroundImage: string;
  endLogomarkAnimation: boolean;
  endedLogomarkAnimation(): any;
  hideLoadingScreen(): any;
}

type IProps = IOwnSpecificProps;

interface ILogomarkSlide {
  backgroundImage: string;
  persist: boolean;
  animateOut: boolean;
}

const Logomark: React.FC<IProps> = ({ finalBackgroundImage, endLogomarkAnimation, ...props }) => {
  const interfaceInitialised = useSelector(fromUserInterface.getInitialised);
  const projectLogomarkBackgrounds = useSelector(fromProjects.getProjectLogomarkBackgrounds);

  const [logomarkSlides, setLogomarkSlides] = React.useState<ILogomarkSlide[]>([]);
  const [transitionEnd, setTransitionEnd] = React.useState(false);
  const [animationDelay] = React.useState(300);
  const [slideDelay] = React.useState(800);
  const [backgroundImages, setBackgroundImages] = React.useState<string[]>([]);
  const [animateIn, setAnimateIn] = React.useState(false);

  React.useEffect(() => {
    if (backgroundImages.length === 0) {
      const newBackgroundImages: string[] = [];
      //console.log(this.props.projectLogomarkBackgrounds);
      projectLogomarkBackgrounds.map(url => {
        newBackgroundImages.push('/assets/images/work/' + url);
        //console.log(url);
      });
      newBackgroundImages.push('/assets/images/logomarkBackground.jpg');
      setBackgroundImages(newBackgroundImages);
    }
  }, [backgroundImages, projectLogomarkBackgrounds]);

  React.useEffect(() => {
    startLogomarkAnimation();
  }, []);

  const latestEndLogomarkAnimation = useLatest(endLogomarkAnimation);
  const latestLogomarkSlides = useLatest(logomarkSlides);
  const latestBackgroundImages = useLatest(backgroundImages);
  const latestFinalBackgroundImage = useLatest(finalBackgroundImage);

  const [triggerSlideInterval, setTriggerSlideInterval] = React.useState(false);
  useInterval(() => {
    if (latestEndLogomarkAnimation.current) {
      triggerEndOfLogomarkAnimation();
    } else {
      triggerNextSlide();
    }
  }, triggerSlideInterval ? animationDelay : null);

  const [triggerSlideTimeout, setTriggerSlideTimeout] = React.useState(false);
  useTimeout(() => {
    setTriggerSlideTimeout(false);
    triggerNextSlide();
    setTriggerSlideInterval(true);
  }, triggerSlideTimeout ? slideDelay : null);

  const startLogomarkAnimation = () => {
    setTransitionEnd(false);
    triggerNextSlide();
    setTriggerSlideTimeout(true);
  };

  const generateUniqueSlideIndex = () => {
    const localBackgroundImages: string[] = latestBackgroundImages.current.filter(image => image !== latestLogomarkSlides.current[latestLogomarkSlides.current.length - 1].backgroundImage);

    const newSlideIndex: number = Math.floor(Math.random() * localBackgroundImages.length);
    const newBackgroundImage = localBackgroundImages[newSlideIndex];
    return newBackgroundImage;
  };

  const triggerNextSlide = (backgroundImage: string = null, persistSlide: boolean = false) => {
    const newLogomarkSlides = [...latestLogomarkSlides.current];

    const previousSlideIndex = newLogomarkSlides.length - 1;

    if (previousSlideIndex > -1)
      newLogomarkSlides[previousSlideIndex].animateOut = true;

    let newBackgroundImage: string = '';

    if (backgroundImage === 'start')
      newBackgroundImage = null;
    else {
      if (backgroundImage != null) {
        newBackgroundImage = '/assets/images/' + backgroundImage;
      }
      else
        newBackgroundImage = generateUniqueSlideIndex();
    }

    newLogomarkSlides.push({
      backgroundImage: newBackgroundImage,
      persist: persistSlide,
      animateOut: false,
    });

    setLogomarkSlides(newLogomarkSlides);
    setAnimateIn(true);
  };

  const [triggerHideLoadingScreenTimeout, setTriggerHideLoadingScreenTimeout] = React.useState(false);
  useTimeout(() => {
    setTriggerHideLoadingScreenTimeout(false);
    props.hideLoadingScreen();
  }, triggerHideLoadingScreenTimeout ? 500 : null);

  const [triggerSlideEndTimeout, setTriggerSlideEndTimeout] = React.useState(false);
  useTimeout(() => {
    setTriggerSlideEndTimeout(false);
    setTransitionEnd(true);
    setTriggerHideLoadingScreenTimeout(true);
  }, triggerSlideEndTimeout ? slideDelay : null);

  const triggerEndOfLogomarkAnimation = () => {
    setTriggerSlideInterval(false);
    triggerNextSlide(latestFinalBackgroundImage.current, true);
    setTriggerSlideEndTimeout(true);
  };

  const signalEndOfLogomarkAnimation = restValues => {
    if (restValues.y === 0) {
      props.endedLogomarkAnimation();
      setAnimateIn(false);
    }
  };

  const animateInSpringConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 340,
    friction: 57,
  };

  const [animateInSpring, setAnimateInSpring] = useSpring(() => ({
    y: 0,
    immediate: false,
    config: animateInSpringConfig,
  }));

  React.useEffect(() => {
    setAnimateInSpring({ y: animateIn ? 100 : 0, immediate: !interfaceInitialised ? true : animateIn ? false : true });
  }, [animateIn]);

  const animateOutSpringConfig: SpringConfig = {
    ...animateInSpringConfig,
    tension: 250,
  };

  const [animateOutSpring, setAnimateOutSpring] = useSpring(() => ({
    y: 100,
    config: animateOutSpringConfig,
    onRest: restValues => { signalEndOfLogomarkAnimation(restValues); },
  }));

  React.useEffect(() => {
    setAnimateOutSpring({ y: transitionEnd ? 0 : 100 });
    setOpacitySpring({ opacity: transitionEnd ? 0 : 1 });
  }, [transitionEnd]);

  const [opacitySpring, setOpacitySpring] = useSpring(() => ({
    opacity: 1,
    config: animateOutSpringConfig,
  }));

  const logomarkContainerStyles = {
    opacity: opacitySpring.opacity,
    height: animateOutSpring.y.to(y => `${y}%`),
    willChange: `height`,
  };

  const imageStyles = {
    height: animateInSpring.y.to(y => `${y}%`),
    top: (transitionEnd ? 0 : 'auto'),
    bottom: (transitionEnd ? 'auto' : 0),
    backgroundPosition: (transitionEnd ? 'top center' : 'bottom center'),
    willChange: `height`,
  };

  const logomarkSlidesComponents = logomarkSlides.map((logomarkSlide, index) => {
    return (
      <LogomarkSlide
        key={index}
        backgroundImage={logomarkSlide.backgroundImage}
        persist={logomarkSlide.persist}
        animateOut={logomarkSlide.animateOut}
      />
    );
  });

  return (
    <a.div className='logomarkContainer' style={logomarkContainerStyles}>
      <div className='logomark'>
        {logomarkSlidesComponents}
        <a.div className='backgroundOverlay simpleImage' style={imageStyles} />
      </div>
    </a.div>
  );
};

export default Logomark;
