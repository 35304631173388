import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as fromUserInterface from 'store/UserInterface';

import { useSpring, a, SpringConfig } from 'react-spring';

import Logomark from './Logomark/Logomark';

import { useLatest, getPixelRatio } from 'utils/Index';

interface IOwnProps {
  id: string;
}

type IProps = IOwnProps & fromUserInterface.ILoadingScreen;

const Screen: React.FC<IProps> = ({ id, visible, logomarkBackground, ...props }) => {
  const interfaceInitialised = useSelector(fromUserInterface.getInitialised);
  const loadingScreens = useSelector(fromUserInterface.getLoadingScreens);
  const dispatch = useDispatch();
  const hideLoadingScreen = React.useCallback((screenId: string, modifyScroll: boolean) => dispatch(fromUserInterface.actionCreators.hideLoadingScreen(screenId, modifyScroll)), [dispatch]);
  const removeLoadingScreen = React.useCallback((screenId: string) => dispatch(fromUserInterface.actionCreators.removeLoadingScreen(screenId)), [dispatch]);
  const animateInPage = React.useCallback((pageId: string) => dispatch(fromUserInterface.actionCreators.animateInPage(pageId)), [dispatch]);

  const latestLoadingScreens = useLatest(loadingScreens);

  const [animatedIn, setAnimatedIn] = React.useState(false);
  const [animateLogomark, setAnimateLogomark] = React.useState(true);
  const [endLogomarkAnimation, setEndLogomarkAnimation] = React.useState(false);

  // 1. Animate in the loading screen on component mount
  const [animateSpring, setAnimateSpring] = useSpring(() => ({
    y: 0,
    from: { y: 1 },
    onRest: restValues => { onRest(restValues); },
    immediate: !interfaceInitialised ? true : false, // If the interface hasn't been initialised it means its a new hard load from the server, so we want to show the loading screen immediately
  }));

  // 2. This triggers the logomark to animate out which will then trigger the screen to animate out once it's finished
  React.useEffect(() => {
    if (animatedIn && !visible) {
      setEndLogomarkAnimation(true);
    }
  }, [animatedIn, visible]);

  // 3. Trigger the loading screen to animate out from the logomark itself
  const hideLoadingScreenFromLogomark = () => {
    setAnimateSpring({ y: -1, immediate: false });
    console.log(latestLoadingScreens.current);

    // This will prevent animating pages in before last loading screen has animated out
    if (Object.keys(latestLoadingScreens.current).length === 1)
      animateInPage(Object.keys(latestLoadingScreens.current)[0]);
  };

  //  If the screen has been animated in mark it as animated in, if the loading screen has been animated out then remove this loading screen from the store completely
  const onRest = restValues => {
    if (restValues.y === 0)
      setAnimatedIn(true);
    else if (restValues.y === -1)
      removeLoadingScreen(id);
  };

  // This will run once the logomark has completely animated out and remove the component from the interface
  const endedLogomarkAnimation = () => {
    setAnimateLogomark(false);
    setEndLogomarkAnimation(false);
  };

  const logomarkProps = {
    finalBackgroundImage: logomarkBackground,
    endLogomarkAnimation,
    endedLogomarkAnimation,
    hideLoadingScreen: hideLoadingScreenFromLogomark,
  };

  const logomarkWrapperStyles = {
    display: animateLogomark ? 'block' : 'none',
  };

  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const [windowWidth, setWindowWidth] = React.useState(400);
  const [windowHeight, setWindowHeight] = React.useState(400);

  React.useLayoutEffect(() => {
    const updateWindowDimensions = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    updateWindowDimensions();

    // Setup event listeners on initial mount to keep the  dimensions of the menu updated
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const ratio = getPixelRatio(ctx);

    const canvasWidth = Math.floor(windowWidth * ratio);
    const canvasHeight = Math.floor(windowHeight * ratio);

    // Sets the size of the canvas in pixels itself
    canvas.width = canvasWidth; 
    canvas.height = canvasHeight;

    // If larger than the canvas width, it will scale and warp it larger, will blur it
    canvas.style.width = `${Math.floor(windowWidth)}px`;
    canvas.style.height = `${Math.floor(windowHeight)}px`;

    function drawScreen() {

      ctx.save();

      ctx.beginPath();
      ctx.translate(0, Math.floor(animateSpring.y.getValue() * canvas.height));
      ctx.rect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = `#000`;
      ctx.fill();

      ctx.restore();

    }

    let requestId;
    const render = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawScreen();

      requestId = requestAnimationFrame(render);
    };

    render();

    return () => {
      cancelAnimationFrame(requestId);
    };

  });

  return (
    <React.Fragment>
      <canvas className='screen' ref={canvasRef} />
      <div className='logomarkWrapper' style={logomarkWrapperStyles}>
        {(animateLogomark ? <Logomark {...logomarkProps} /> : null)}
      </div>
    </React.Fragment>
  );
};

export default Screen;
