import * as React from 'react';
import { connect } from 'react-redux';

import { withCookies, ReactCookieProps } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';

import classNames from 'classnames';

import { IApplicationState } from 'store/index';
import * as fromUserInterface from 'store/UserInterface';

import { useSpring, a, to } from 'react-spring';

import { useLatest } from 'utils/Index';

import { useAnimateInTrail } from 'hooks/useAnimateInTrail';
import { initialOffset, animateInSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

import { ButtonLink } from 'components/Links/Index';

type IOwnProps = ReactCookieProps;

interface IStateProps {
  animateContentIn: boolean;
}

type IDispatchProps = typeof fromUserInterface.actionCreators;

type IProps = IStateProps & IDispatchProps & IOwnProps;

const CookieModalComponent: React.FC<IProps> = ({ animateContentIn, cookies, ...props }) => {
  const [isActive, setIsActive] = React.useState(false);
  const [hideModal, setHideModal] = React.useState(false);
  const [animationReady, setAnimationReady] = React.useState(false);
  const [animateIn, setAnimateIn] = React.useState(false);
  const [cookieOptions] = React.useState({
    path: '/',
    maxAge: 60 * 60 * 24 * 365.25,
    secure: false,
    httpOnly: false,
    sameSite: true,
  } as CookieSetOptions);

  React.useEffect(() => {
    if (animateContentIn && !animateIn)
      setAnimateIn(true);
  }, [animateContentIn, animateIn]);

  React.useEffect(() => {
    let cookieModalDismissedValue: string = 'false';

    if (typeof cookies.get('cookieModalDismissed') !== 'undefined')
      cookieModalDismissedValue = cookies.get('cookieModalDismissed');

    const cookieModalDismissed: boolean = cookieModalDismissedValue === 'true' ? true : false;
    cookies.set('cookieModalDismissed', cookieModalDismissed, cookieOptions);

    setIsActive(!cookieModalDismissed);
    setHideModal(cookieModalDismissed);
    setAnimationReady(cookieModalDismissed ? false : true);

  }, [cookies, cookieOptions]);

  const closeModal = () => {
    cookies.set('cookieModalDismissed', true, cookieOptions);
    if (isActive) {
      setHideModal(true);
    }
  };

  const latestIsActive = useLatest(isActive);
  const latestHideModal = useLatest(hideModal);

  const disableCookieModal = () => {
    if (latestIsActive && latestHideModal)
      setIsActive(false);
  };

  const animateInSpring = useAnimateInTrail(animationReady && animateIn, 4);

  const [animateOutSpring, setAnimateOutSpring] = useSpring(() => ({ x: 1, config: animateInSpringConfig, onRest: () => disableCookieModal() }));

  React.useEffect(() => {
    setAnimateOutSpring({ x: hideModal ? 0 : 1 });
  }, [hideModal]);

  const modalWrapperStyles = {
    opacity: to(
      [animateInSpring[0].active, animateOutSpring.x],
      (anin, anout) => anin * anout * (isActive ? 1 : 0),
    ),
    transform: animateInSpring[0].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const closeButtonStyles = {
    opacity: animateInSpring[1].active,
  };

  const titleStyles = closeButtonStyles;

  const descriptionStyles = {
    opacity: animateInSpring[2].active,
  };

  const ctaStyles = {
    opacity: animateInSpring[3].active,
  };

  if (animationReady)
    return (
      <a.div
        className={classNames(
          'cookieModalWrapper',
          { active: isActive },
        )}
        style={modalWrapperStyles}
      >
        <div className='background' />
        <a.div className='closeButton' style={closeButtonStyles}>
          <div className='trigger' onClick={closeModal}>
            <span />
            <span />
          </div>
        </a.div>
        <a.h2 style={titleStyles}>
          Who doesn't like cookies?
        </a.h2>
        <a.p style={descriptionStyles}>
          We use cookies to enhance your browsing experience as well as to help personalise our content and site for you . We'll just assume you're fine with that whilst you continue using our site. Sweet.
        </a.p>
        <a.div className='ctaWrapper' style={ctaStyles}>
          <ButtonLink
            onClick={closeModal}
            type='extraSmall'
            arrow
            animationReady={animationReady}
            animateIn={animateInSpring[3].active.to(x => x > 0 ? 1 : 0)}
            title='Sounds good'
          >
            Sounds good
          </ButtonLink>
        </a.div>
      </a.div>
    );
  else
    return null;
};

// Selects which state properties are merged into the component's props
const mapStateToProps = (state: IApplicationState, ownProps: IOwnProps): IStateProps => ({
  animateContentIn: fromUserInterface.getPageContentAnimatedIn(state),
  //animateContentIn: fromUserInterface.getNewPageAnimateContentIn(state),
});

// Selects which action creators are merged into the component's props
const mapDispatchToProps: IDispatchProps = fromUserInterface.actionCreators;

// Wire up the React component to the Redux store
export default withCookies(connect<IStateProps, IDispatchProps, IOwnProps>
  (mapStateToProps, mapDispatchToProps)(CookieModalComponent));
