import * as React from 'react';
import { useSpring, SpringConfig } from 'react-spring';

export const useScrollTarget = (
  cancelAnimationOnManualScroll = true,
  onComplete: (restValues: object) => void = restValues => null,
  onStop: () => void = () => null,
) => {
  const [target, setTarget] = React.useState(null);

  const springConfig: SpringConfig = {
    mass: 1,
    tension: 270,
    friction: 36,
    clamp: true,
    precision: 0.01,
    velocity: 0,
    //duration: undefined,
    easing: t => t,
  };

  const scrollRest = (restValues: object) => {
    if (onComplete != null)
      onComplete(restValues);
  };

  const [, set, stop] = useSpring(() => ({
    y: 0,
    config: springConfig,
    onRest: restValues => { scrollRest(restValues); },
  }));

  React.useEffect(() => {
    if (target == null) {
      stop();
      if (onStop != null)
        onStop();
    }
    else
      set({
        y: target,
        reset: true,
        from: { y: window.scrollY },
        onFrame: props => window.scroll(0, props.y as any),
      });
  }, [target]);

  React.useEffect(() => {
    if (cancelAnimationOnManualScroll) {
      // Setup event listeners on initial mount for cancelling scroll animations if the user is still scrolling
      const handleScroll = event => {
        setTarget(null);
      };

      window.addEventListener('wheel', handleScroll);

      return () => {
        window.removeEventListener('wheel', handleScroll);
      };
    }
  }, []);

  return setTarget;
};



//export const useScrollTarget = (
//  cancelAnimationOnManualScroll = true,
//  onComplete: (restValues: object) => void = (restValues) => null,
//  onStop: () => void = () => null,
//) => {
//  const [target, setTarget] = React.useState(null);

//  const springConfig: SpringConfig = {
//    mass: 1,
//    tension: 270,
//    friction: 36,
//    clamp: true,
//    precision: 0.01,
//    velocity: 0,
//    //duration: undefined,
//    easing: t => t,
//  };

//  const scrollRest = (restValues: object) => {
//    onComplete(restValues);
//  }

//  const [, set, stop] = useSpring(() => ({
//    y: 0,
//    config: defaultSpringConfig,
//    onRest: (restValues) => { scrollRest(restValues) },
//  }));

//  const componentRef = React.useRef<HTMLDivElement>(null);

//  React.useEffect(() => {
//    console.log(target);
//    if (componentRef.current != null)
//      console.log(componentRef.current.scrollTop);

//    if (target == null) {
//      stop();
//      onStop();
//    }
//    else {
//      if (componentRef.current != null)
//        set({
//          y: target,
//          reset: true,
//          from: { y: componentRef.current.scrollTop },
//          onFrame: props => componentRef.current.scrollTop = props.y,
//          //onFrame: props => props != null ? componentRef.current.scrollTo(0, props.y as any) : null,
//        });
//    }
//  }, [target, componentRef.current]);

//  React.useLayoutEffect(() => {
//    if (cancelAnimationOnManualScroll && componentRef.current != null) {
//      // Setup event listeners on initial mount for cancelling scroll animations if the user is still scrolling
//      const handleScroll = event => {
//        setTarget(null);
//      }

//      componentRef.current.addEventListener('wheel', handleScroll);

//      return () => {
//        componentRef.current.removeEventListener('wheel', handleScroll);
//      }
//    }
//  }, [componentRef.current]);

//  let returnValues: [React.MutableRefObject<HTMLDivElement>, (value: any) => void];
//  returnValues = [componentRef, setTarget]

//  return returnValues;
//}
