//import 'babel-polyfill';
import { Action, Reducer } from 'redux';
import { IApplicationState } from './index';
import * as fromPageScroll from './PageScroll';

import { generateUid } from 'utils/Index';

import { createSelector } from 'reselect';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ILoadingScreen {
  visible: boolean;
  logomarkBackground: string;
  animatePageIn: boolean;
  animatePageContentIn: boolean;
}

export interface INewPage {
  //initialised: boolean;
  animateIn: boolean;
  animateContentIn: boolean;
}

export interface ILoadingScreens {
  [id: string]: ILoadingScreen;
}

export interface IUserInterfaceState {
  initialised: boolean;
  loadingScreens: ILoadingScreens;
  currentLoadingScreen: string;
  //newPage: INewPage;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export enum keys {
  MARK_AS_INITIALISED = 'MARK_AS_INITIALISED',
  NEW_LOADING_SCREEN = 'NEW_LOADING_SCREEN',
  HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN',
  REMOVE_LOADING_SCREEN = 'REMOVE_LOADING_SCREEN',
  CHANGE_LOADING_SCREEN_LOGOMARK_BACKGROUND = 'CHANGE_LOADING_SCREEN_LOGOMARK_BACKGROUND',
  ANIMATE_PAGE_IN = 'ANIMATE_PAGE_IN',
  ANIMATE_PAGE_CONTENT_IN = 'ANIMATE_PAGE_CONTENT_IN',
}

interface IMarkAsInitialised {
  type: keys.MARK_AS_INITIALISED;
}
interface INewLoadingScreenAction {
  type: keys.NEW_LOADING_SCREEN;
  id: string;
}
interface IHideLoadingScreenAction {
  type: keys.HIDE_LOADING_SCREEN;
  id: string;
}
interface IRemoveLoadingScreenAction {
  type: keys.REMOVE_LOADING_SCREEN;
  id: string;
}
interface IChangeLoadingScreenLogomarkBackground {
  type: keys.CHANGE_LOADING_SCREEN_LOGOMARK_BACKGROUND;
  url: string;
  id: string;
}
interface IAnimatePageInAction {
  type: keys.ANIMATE_PAGE_IN;
  id: string;
}
interface IAnimatePageContentInAction {
  type: keys.ANIMATE_PAGE_CONTENT_IN;
  id: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type IKnownAction =
  IMarkAsInitialised
  | INewLoadingScreenAction
  | IHideLoadingScreenAction
  | IRemoveLoadingScreenAction
  | IChangeLoadingScreenLogomarkBackground
  | IAnimatePageInAction
  | IAnimatePageContentInAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
// We are using them to fire off our events that will be handled by the root sagas and delegated consequently

let animatePageInTimer;
let animatePageContentInTimer;

export const actionCreators = {
  markAsInitialised: () => dispatch => {
    dispatch({ type: keys.MARK_AS_INITIALISED });
  },
  triggerNewLoadingScreen: (id: string, recordPageScroll: boolean = true, action: string) => dispatch => {
    clearTimeout(animatePageInTimer);
    clearTimeout(animatePageContentInTimer);
    dispatch(fromPageScroll.actionCreators.disablePageScroll(recordPageScroll));
    dispatch({ type: keys.NEW_LOADING_SCREEN, id });
  },
  hideLoadingScreen: (id: string, modifyScroll: boolean = true, newPage: boolean = false) => dispatch => {
    dispatch({ type: keys.HIDE_LOADING_SCREEN, id });
    if (modifyScroll) {
      if (newPage) {
        dispatch(fromPageScroll.actionCreators.enablePageScroll());
      } else {
        dispatch(fromPageScroll.actionCreators.restorePageScroll());
      }
    }
  },
  removeLoadingScreen: (id: string) => dispatch => {
    dispatch({ type: keys.REMOVE_LOADING_SCREEN, id });
  },
  changeLoadingScreenLogomarkBackground: (id: string, url: string) => dispatch => {
    dispatch({
      type: keys.CHANGE_LOADING_SCREEN_LOGOMARK_BACKGROUND,
      url,
      id,
    });
  },
  animateInPage: (id: string) => dispatch => {
    animatePageInTimer = setTimeout(() => {
      dispatch({ type: keys.ANIMATE_PAGE_IN, id });
      animatePageContentInTimer = setTimeout(() => {
        dispatch({ type: keys.ANIMATE_PAGE_CONTENT_IN, id });
      }, 200);
    }, 30);
  },
};

// ----------------
// SAGA WATCHERS - Register all saga watchers here that will intercept all dispatched calls and delegate appropriately

// ----------------
// SAGA WORKERS - These are saga worker functions that are called when receiving saga dispatches. The saga watchers
// intercept dispatched calls and call the relevant saga functions when appropriate

// ----------------
// ROOT SAGA - Register all saga watchers into one root saga to be initialised in configureStore

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IUserInterfaceState> = (state: IUserInterfaceState, action: IKnownAction) => {
  switch (action.type) {
    case keys.MARK_AS_INITIALISED:
      return {
        ...state,
        initialised: true,
      };
    case keys.NEW_LOADING_SCREEN:
      return {
        ...state,
        loadingScreens: {
          ...state.loadingScreens,
          [action.id]: {
            visible: true,
            logomarkBackground: null,
            animatePageIn: false,
            animatePageContentIn: false,
          },
        },
        currentLoadingScreen: action.id,
      };
    case keys.HIDE_LOADING_SCREEN:
      if (state.loadingScreens.hasOwnProperty(action.id))
        return {
          ...state,
          loadingScreens: {
            ...state.loadingScreens,
            [action.id]: {
              ...state.loadingScreens[action.id],
              visible: false,
            },
          },
        };
      else return state;
    case keys.REMOVE_LOADING_SCREEN:
      const { [action.id]: value, ...rest } = state.loadingScreens;
      return {
        ...state,
        loadingScreens: rest,
      };
    case keys.CHANGE_LOADING_SCREEN_LOGOMARK_BACKGROUND:
      return {
        ...state,
        loadingScreens: {
          ...state.loadingScreens,
          [action.id]: {
            ...state.loadingScreens[action.id],
            logomarkBackground: action.url,
          },
        },
      };
    case keys.ANIMATE_PAGE_IN:
      return {
        ...state,
        loadingScreens: {
          ...state.loadingScreens,
          [action.id]: {
            ...state.loadingScreens[action.id],
            animatePageIn: true,
          },
        },
      };
    case keys.ANIMATE_PAGE_CONTENT_IN:
      return {
        ...state,
        loadingScreens: {
          ...state.loadingScreens,
          [action.id]: {
            ...state.loadingScreens[action.id],
            animatePageContentIn: true,
          },
        },
      };
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      const exhaustiveCheck: never = action;
  }

  const initialLoadingScreenId = generateUid();

  // For unrecognized actions (or in cases where actions have no effect), must return the existing state
  //  (or default initial state if none was supplied)
  return state || {
    initialised: false,
    loadingScreens: {},
    currentLoadingScreen: null,
  };
};

// ----------------
// SELECTORS - These are functions exposed to UI components that will give them access to the associated store components.
// They only return the reference to the required state in the store, they don't change it.

const userInterfaceSelector = (state: IApplicationState) => state.userInterface;
export const getUserInterface = createSelector(
  [userInterfaceSelector],
  userInterface => {
    //console.log("Output selector running: getUserInterface");
    return userInterface;
  },
);

const initiliasedSelector = (state: IApplicationState) => userInterfaceSelector(state).initialised;
export const getInitialised = createSelector(
  [initiliasedSelector],
  initialised => {
    //console.log("Output selector running: getLoadingScreen");
    return initialised;
  },
);

const loadingScreensSelector = (state: IApplicationState) => userInterfaceSelector(state).loadingScreens;
const currentLoadingScreenSelector = (state: IApplicationState) => userInterfaceSelector(state).currentLoadingScreen;
export const getLoadingScreens = createSelector(
  [loadingScreensSelector],
  loadingScreens => {
    //console.log("Output selector running: getLoadingScreen");
    return loadingScreens;
  },
);

export const getVisibleLoadingScreens = createSelector(
  [loadingScreensSelector],
  loadingScreens => {
    //console.log("Output selector running: getLoadingScreen");
    const visibleScreens = [];
    Object.keys(loadingScreens).map(id => {
      if (loadingScreens[id].visible === true)
        visibleScreens.push(id);
    });
    return visibleScreens.length;
  },
);

export const getPageContentAnimatedIn = createSelector(
  [currentLoadingScreenSelector, loadingScreensSelector],
  (currentLoadingScreenId, loadingScreens) => {
    //console.log("Output selector running: getPageAnimatedIn");
    let pageAnimatedIn = false;
    const loadingScreenIds = Object.keys(loadingScreens);
    if (currentLoadingScreenId != null) {
      let screenAnimatedIn = true;
      loadingScreenIds.map(id => {
        if (loadingScreens[id].animatePageContentIn === false)
          screenAnimatedIn = false;
      });
      pageAnimatedIn = screenAnimatedIn;
    }
    return pageAnimatedIn;
  },
);

export const makeGetLoadingScreen = () => createSelector(
  loadingScreensSelector,
  (_, screenId) => screenId,
  (loadingScreens, screenId) => {
    //console.log("Output selector running: getLoadingScreen");
    console.log('screenId', screenId);
    const emptyLoadingScreen: ILoadingScreen = {
      visible: false,
      logomarkBackground: null,
      animatePageIn: true,
      animatePageContentIn: true,
    };
    return loadingScreens.hasOwnProperty(screenId) ? loadingScreens[screenId] : emptyLoadingScreen;
  },
);
