import * as React from 'react';
import { useSelector } from 'react-redux';

import * as fromPageScroll from 'store/PageScroll';

export const DisableScroll: React.FC<{}> = () => {
  const pageScrollEnabled = useSelector(fromPageScroll.getEnabled);
  const pageScrollTop = useSelector(fromPageScroll.getTop);

  //React.useEffect(() => {
  //  const handleResize = () => {
  //    const newViewportWidth: number = window.innerWidth;

  //    if (newViewportWidth !== viewportWidth)
  //      props.updateViewportWidth(newViewportWidth);
  //  }

  //  window.addEventListener('resize', handleResize, { passive: true });
  //  window.addEventListener('scroll', handleScroll, { passive: true });
  //  return () => {
  //    window.removeEventListener('resize', handleResize);
  //    window.removeEventListener('scroll', handleScroll);
  //  }
  //}, []);

  React.useEffect(() => {
    const documentClasses = document.documentElement.className;
    const disabledClass = 'disable-scroll';

    const windowScrollTop = (typeof window !== 'undefined' ? window.scrollY : 0);

    if (!pageScrollEnabled) {
      if (documentClasses.indexOf(disabledClass) === -1) {
        document.documentElement.className = documentClasses + ' ' + disabledClass;
      }
    } else if (pageScrollEnabled && windowScrollTop === 0) {
      if (documentClasses.indexOf(disabledClass) !== -1) {
        //console.log('RESETTING SCROLL POSITION ON OLD PAGE');
        document.documentElement.className = documentClasses.replace(disabledClass, '');
        window.scrollTo(0, pageScrollTop);
      }
    }
  }, [pageScrollEnabled, pageScrollTop]);

  //const handleScroll = () => {
  //  const body = document.body;
  //  const html = document.documentElement;

  //  const newPageHeight = Math.max(body.scrollHeight, body.offsetHeight,
  //    html.clientHeight, html.scrollHeight, html.offsetHeight);

  //  const scrollTrackerElement = scrollTracker.current;

  //  const scrollTrackerProps = scrollTrackerElement
  //    .getBoundingClientRect(); //outputs <scrollTracker> sizing

  //  const scrollTop: number = scrollTrackerProps.top;
  //  const newViewportHeight: number = window.innerHeight;
  //  const newViewportWidth: number = window.innerWidth;

  //  if (newPageHeight !== pageHeight)
  //    props.updatePageHeight(newPageHeight);

  //  if (newViewportHeight !== viewportHeight)
  //    props.updateViewportHeight(viewportHeight);

  //  if (newViewportWidth !== viewportWidth)
  //    props.updateViewportWidth(viewportWidth);
  //}

  return null;
};
