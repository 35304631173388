import * as React from 'react';
import { useTrail, SpringConfig } from 'react-spring';
import { animateInSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

export interface IAnimateInProps {
  animationReady: boolean;
}

export const useAnimateInTrail = (animateInStatus: boolean, amount: number, customSpringConfig: SpringConfig = {}) => {

  //const onFrame = (frameValues) => {
  //  console.log('frame ', frameValues);
  //}

  const [animateInSpring, setAnimateInSpring] = useTrail(amount, () => ({
    active: 0,
    config: customSpringConfig !== {} ? customSpringConfig : animateInSpringConfig,
    //onFrame: (frameValues) => onFrame(frameValues),
  }));

  React.useEffect(() => {
    setAnimateInSpring({
      active: animateInStatus ? 1 : 0,
    });
  }, [animateInStatus]);

  return animateInSpring;
};
