import * as React from 'react';
import { Link } from 'react-router-dom';

import { useSpring, a, to as springTo, SpringConfig } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

import classNames from 'classnames';

import { preloadRouteComponent } from 'app/routes';

import { useTimeout } from 'utils/Index';

export interface IOwnSpecificProps {
  to?: string;
  type: 'extraSmall' | 'small' | 'medium' | 'large';
  scheme?: string;
  backgroundShade?: 'light' | 'dark';
  backgroundColor?: string;
  arrow?: boolean;
  id?: string;
  isActive?: boolean;
  animateIn?: number;
  animationReady?: boolean;
  onClick?: () => void;
  title: string;
}

type IOwnProps = IOwnSpecificProps;

type IProps = IOwnProps;

const ButtonLinkComponent: React.FC<IProps> = React.forwardRef((
  {
    animateIn,
    animationReady,
    to = null,
    type,
    scheme,
    id,
    isActive,
    backgroundShade,
    backgroundColor,
    title,
    arrow,
    ...props
  },
  ref,
) => {
  const myRef = React.useRef();
  React.useImperativeHandle(ref, () => ({}));

  const [clicked, setClicked] = React.useState(false);
  const [hovering, setHovering] = React.useState(false);
  const [backgroundWidth, setBackgroundWidth] = React.useState(0);
  const [textMargin, setTextMargin] = React.useState(0);

  const [delay, setDelay] = React.useState(500);
  const [triggerTimeout, setTriggerTimeout] = React.useState(false);

  useTimeout(() => {
    setClicked(false);
  }, triggerTimeout ? delay : null);

  React.useEffect(() => {
    let newBackgroundWidth;
    let newTextMargin;

    if (type === 'large') {
      newBackgroundWidth = 60;
      newTextMargin = 20;
    }
    else if ((type === 'medium') || (type === 'small') || (type === 'extraSmall')) {
      newBackgroundWidth = 30;
      newTextMargin = 10;
    }
    if (newBackgroundWidth !== backgroundWidth)
      setBackgroundWidth(newBackgroundWidth);
    if (newTextMargin !== textMargin)
      setTextMargin(newTextMargin);
  }, [backgroundWidth, textMargin, type]);

  const handleClick = () => {
    if (props.onClick)
      props.onClick();
    setTriggerTimeout(false);
    setClicked(true);
    setTriggerTimeout(true);
  };

  const handleMouseEnter = () => {
    if (!hovering)
      setHovering(true);
    if (to != null)
      preloadRouteComponent(to);
  };

  const handleMouseLeave = () => {
    if (hovering || clicked) {
      setHovering(false);
      setClicked(false);
    }
  };

  const ctaArrow = () => {
    if (arrow)
      return <span className='arrow'>&#8250;</span>;
    else
      return null;
  };

  const backgroundStartRatio = 8;

  const inputProps: { [k: string]: any } = {
    className: classNames(
      'button-link',
      type,
      { [scheme]: (scheme != null) },
      { ['background-' + backgroundShade]: (backgroundShade != null) },
    ),
    id,
    onClick: handleClick,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    title,
  };

  const backgroundSpringConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 50,
    friction: 36,
  };

  const [backgroundInitialisationSpring, setBackgroundInitialisation] = useSpring(() => ({
    x: 0,
    config: backgroundSpringConfig,
  }));

  const animateInSpringConfig: SpringConfig = {
    ...backgroundInitialisationSpring,
    tension: 35,
  };

  const [animateInSpring, setAnimateInSpring] = useSpring(() => ({
    x: 0,
    config: animateInSpringConfig,
  }));

  React.useEffect(() => {
    setBackgroundInitialisation({ x: animationReady && (animateIn > 0) ? 1 : 0 });
    setAnimateInSpring({ x: animationReady && (animateIn > 0) ? 1 : 0 });
  }, [animationReady, animateIn]);

  const hoveringSpringConfig: SpringConfig = {
    ...backgroundInitialisationSpring,
    tension: 340,
  };

  const [hoveringSpring, setHoveringSpring] = useSpring(() => ({
    x: 0,
    config: hoveringSpringConfig,
  }));

  React.useEffect(() => {
    setHoveringSpring({ x: hovering ? 1 : 0 });
  }, [hovering]);

  const [hoveringClickedSpring, setHoveringClickedSpring] = useSpring(() => ({
    x: 0,
    config: hoveringSpringConfig,
  }));

  React.useEffect(() => {
    setHoveringClickedSpring({ x: hovering ? (clicked ? 10 : 7) : 0 });
  }, [hovering, clicked]);

  const backgroundStyles = {
    backgroundColor: (backgroundColor != null ? backgroundColor : null),
    transform: springTo(
      [backgroundInitialisationSpring.x, hoveringSpring.x, hoveringClickedSpring.x],
      (backgroundInitialisation, y, z) => `translateX(calc( ((${(backgroundInitialisation <= (backgroundStartRatio / 10) ? 1 : 0)} * -200%) + (${(backgroundInitialisation <= (backgroundStartRatio / 10) ? backgroundInitialisation * (10 / backgroundStartRatio) : 0)} * 300%)) + ((${(backgroundInitialisation > (backgroundStartRatio / 10) ? 1 : 0)} * -100%) + (${(backgroundInitialisation > (backgroundStartRatio / 10) ? (backgroundInitialisation - (backgroundStartRatio / 10)) * (10 / (10 - backgroundStartRatio)) : 0)} * ${backgroundWidth}px)) + (${y} * -${backgroundWidth}px) + (${z} * 10%) ))`,
    ),
  };

  const labelStyles = {
    transform: animateInSpring.x.to(x => `translateX(calc((${x} * ${textMargin}px) - ${textMargin}px))`),
  };

  const LinkBody = (
    <div>
      <a.div className='background' style={backgroundStyles} />
      <a.div className='label' style={labelStyles}>
        {props.children} {ctaArrow()}
      </a.div>
    </div>
  );

  const LinkText = <span className='spacerText'>{props.children} {ctaArrow()}</span>;

  if (to == null)
    return (
      <div ref={myRef} {...inputProps} >
        {LinkBody}
        {LinkText}
      </div>
    );
  else
    return (
      <Link ref={myRef} to={to} {...inputProps}>
        {LinkBody}
        {LinkText}
      </Link>
    );
});

export const ButtonLink = a(ButtonLinkComponent);
//export default a(ButtonLink);
