import * as React from 'react';

export const useScrollPosition = () => {
  const [scrollY, setScrollY] = React.useState(0);

  React.useEffect(() => {

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollY;
};
