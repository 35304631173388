import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as fromUserInterface from 'store/UserInterface';

import Screen from './Screen';

//import { generateUid } from 'utils/Index';

const LoadingScreen: React.FC<{}> = () => {
  const loadingScreens = useSelector(fromUserInterface.getLoadingScreens);
  //const dispatch = useDispatch();
  //const triggerNewLoadingScreen = React.useCallback((id: string, recordPageScroll: boolean) => dispatch(fromUserInterface.actionCreators.triggerNewLoadingScreen(id, recordPageScroll, 'PUSH')), [dispatch]);

  //const [screens, setScreens] = React.useState(0);

  //React.useEffect(() => {
  //  setScreens(Object.keys(loadingScreens).length);
  //}, [loadingScreens]);

  //const triggerNewScreen = () => {
  //  const newId = generateUid();
  //  let recordPageScroll = true;
  //  if (Object.keys(loadingScreens).length !== 0)
  //    recordPageScroll = false;
  //  triggerNewLoadingScreen(newId, recordPageScroll);
  //}

  return (
    <div className='loadingScreenWrapper'>
      {Object.keys(loadingScreens).map((id, index) => {
        return <Screen key={id} id={id} {...loadingScreens[id]} />;
      })}
    </div>
  );
};

export default LoadingScreen;

//<React.Fragment>
//  <div className='screenControls'>
//    {screens}
//    <div className='button' onClick={triggerNewScreen}>New page</div>
//  </div>
//  <div className='loadingScreenWrapper'>
//    {Object.keys(loadingScreens).map((id, index) => {
//      return <Screen key={id} id={id} {...loadingScreens[id]} />;
//    })}
//  </div>
//</React.Fragment>
