import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BlockLink from 'components/Links/BlockLink/BlockLink';

import { useSpring, a, to, SpringConfig } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

//import { Stage, Layer, Rect, Text, Image } from 'react-konva';

import classNames from 'classnames';

import { IApplicationState } from 'store/index';
import * as fromMainNavigation from 'store/MainNavigation';

type IBackgroundShade = 'light' | 'dark';

import { useInterval, useLatest } from 'utils/Index';

interface IProps {
  backgroundShade: IBackgroundShade;
  mainSiteOverlay: boolean;
}

const HeaderMenuComponent: React.FC<IProps> = ({ mainSiteOverlay, backgroundShade }) => {
  const [animationReady, setAnimationReady] = React.useState(false);

  const navMenu = useSelector(fromMainNavigation.getNavMenu);
  const navLogo = useSelector(fromMainNavigation.getNavLogo);
  const homeUrl = useSelector(fromMainNavigation.getHomeUrl);
  const dispatch = useDispatch();
  const enterNavMenuTriggerAction = React.useCallback(() => dispatch(fromMainNavigation.actionCreators.enterNavMenuTriggerAction()), [dispatch]);
  const leaveNavMenuTriggerAction = React.useCallback(() => dispatch(fromMainNavigation.actionCreators.leaveNavMenuTriggerAction()), [dispatch]);
  const showNavMenuAction = React.useCallback(() => dispatch(fromMainNavigation.actionCreators.showNavMenuAction()), [dispatch]);
  const hideNavMenuAction = React.useCallback(() => dispatch(fromMainNavigation.actionCreators.hideNavMenuAction()), [dispatch]);
  const preloadNavMenuAction = React.useCallback(() => dispatch(fromMainNavigation.actionCreators.preloadNavMenuAction()), [dispatch]);
  const handleMainNavClickAction = React.useCallback((linkId: number) => dispatch(fromMainNavigation.actionCreators.handleMainNavClickAction(linkId)), [dispatch]);
  const enterNavLogoAction = React.useCallback(() => dispatch(fromMainNavigation.actionCreators.enterNavLogoAction()), [dispatch]);
  const leaveNavLogoAction = React.useCallback(() => dispatch(fromMainNavigation.actionCreators.leaveNavLogoAction()), [dispatch]);

  React.useEffect(() => {
    setAnimationReady(true);
  }, []);

  const handleNavMenuTriggerMouseEnter = () => {
    if (mainSiteOverlay || !navMenu.loaded)
      enterNavMenuTriggerAction();
  };

  const handleNavMenuTriggerMouseLeave = () => {
    if (mainSiteOverlay || !navMenu.loaded)
      leaveNavMenuTriggerAction();
  };

  const [preloadNavMenuInterval, setPreloadNavMenuInterval] = React.useState(false);
  const latestNavMenuLoaded = useLatest(navMenu.loaded);
  useInterval(() => {
    if (latestNavMenuLoaded.current) {
      setPreloadNavMenuInterval(false);
      showNavMenuAction();
    }
  }, preloadNavMenuInterval ? 10 : null);

  const triggerNavMenu = () => {
    if (navMenu.open)
      hideNavMenuAction();
    else
      if (navMenu.loaded) {
        showNavMenuAction();
      } else {
        preloadNavMenuAction();
        setPreloadNavMenuInterval(true);
      }
  };

  const springConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 250,
  };

  const [navMenuOpenSpring, setNavMenuOpenSpring] = useSpring(() => ({ x: 0, config: springConfig }));

  React.useEffect(() => {
    setNavMenuOpenSpring({ x: navMenu.open && animationReady ? 1 : 0 });
  }, [navMenu.open, animationReady]);

  const menuIconSpringConfig: SpringConfig = {
    ...springConfig,
    tension: 340,
    friction: 25,
  };

  const [menuIconSpring, setMenuIconSpring] = useSpring(() => ({ x: 0, config: menuIconSpringConfig }));

  React.useEffect(() => {
    setMenuIconSpring({ x: (navMenu.open || navMenu.triggerHovering) && animationReady ? 1 : 0, immediate: (navMenu.open || navMenu.triggerHovering) && animationReady ? true : false });
  }, [navMenu.open, animationReady, navMenu.triggerHovering]);

  const [navLogomarkSpring, setNavLogomarkSpring] = useSpring(() => ({
    x: 0,
    config: springConfig,
  }));

  React.useEffect(() => {
    setNavLogomarkSpring({ x: navLogo.markVisible && !navMenu.open ? 1 : 0 });
  }, [navLogo.markVisible, navMenu.open]);

  const logoStyles = {
    width: navLogomarkSpring.x.to(x => `calc((1 - ${x}) * 82px)`),
    //opacity: menuIconSpring.x.to(x => mainSiteOverlay ? x : 1 - x),
    opacity: to(
      [navLogomarkSpring.x, navMenuOpenSpring.x],
      (mark, menuOpen) => (
        mainSiteOverlay ?
          navMenu.triggerHovering ? 1 : (navMenu.open ? 1 : (menuOpen !== 0 ? menuOpen : 0))
          :
          (navMenu.open || navMenu.triggerHovering) && animationReady ? 0 : (1 - mark)
      ),
    ),
    display: to(
      [navLogomarkSpring.x, navMenuOpenSpring.x],
      (mark, menuOpen) => (
        (mark !== 1 && !mainSiteOverlay)
          || (((menuOpen !== 0) || (navMenu.triggerHovering) || (navMenu.open && animationReady)) && mainSiteOverlay) ? `block` : `none`
      ),
    ),
  };

  const logomarkStyles = {
    //transform: `translateX(calc((1 - ${navLogomark}) * (82px - 20px)))`,
    marginLeft: navLogomarkSpring.x.to(x => `calc((1 - ${x}) * (82px - 20px) - 3px)`),
    //opacity: navLogomarkSpring.x,
    opacity: to(
      [navLogomarkSpring.x, navMenuOpenSpring.x],
      (mark, menuOpen) => (
        mainSiteOverlay ?
          navMenu.open ? (1 - menuOpen) * mark : (menuOpen !== 0 ? menuOpen * mark : navMenu.triggerHovering ? 1 : 0)
          :
          (navMenu.open || navMenu.triggerHovering) && animationReady ? 0 : mark
      ),
    ),
    display: to(
      [navLogomarkSpring.x, navMenuOpenSpring.x],
      (mark, menuOpen) => (
        //mainSiteOverlay ?
        mark === 0 ? 'none' : 'block'
        //:
        //(navMenu.open || navMenu.triggerHovering) && animationReady ? 0 : mark
      ),
    ),
    //display: navLogomarkSpring.x.to(x => x === 0 || ((!navMenu.open || !navMenu.triggerHovering) && mainSiteOverlay) ? 'none' : 'block'),
  };

  const menuIconStyles = {
    opacity: menuIconSpring.x.to(x => mainSiteOverlay ? x : 1 - x),
  };

  const menuIconTopLineStyles = {
    transform: navMenuOpenSpring.x.to(x => `rotate(calc(${x} * 135deg))`),
    top: navMenuOpenSpring.x.to(x => `calc(4px + (${x} * 7px))`),
  };

  const menuIconMiddleLineStyles = {
    transform: navMenuOpenSpring.x.to(x => `rotate(calc(${x} * 90deg))`),
    opacity: navMenuOpenSpring.x.to(x => 1 - x),
  };

  const menuIconBottomLineStyles = {
    transform: navMenuOpenSpring.x.to(x => `rotate(calc(${x} * 45deg))`),
    top: navMenuOpenSpring.x.to(x => `calc(18px - (${x} * 7px))`),
  };

  return (
    <div className='header'>
      <a.div
        className={classNames(
          'nav-header',
          'backgroundShade-' + backgroundShade,
          { mainSiteOverlay },
          //{ active: this.state.menuIconHovering },
          //{ active: props.navMenu.triggerHovering },
          { active: (navMenuOpenSpring.x.to(x => x > 0 && mainSiteOverlay ? 1 : 0)) || (navMenu.triggerHovering && mainSiteOverlay) || (navMenu.open && animationReady && mainSiteOverlay) },
        )}
      >
        <BlockLink
          to={homeUrl}
          onClick={() => handleMainNavClickAction(1)}
          onMouseEnter={preloadNavMenuAction}
          title='Home'
        >
          <a.div
            className={classNames(
              'logo',
              { navMenuOpen: navMenu.open },
              { active: navLogo.hovering },
            )}
            style={logoStyles}
            onMouseEnter={enterNavLogoAction}
            onMouseLeave={leaveNavLogoAction}
          />
          <a.div
            className={classNames(
              'logomark',
              { navMenuOpen: navMenu.open },
              { active: navLogo.hovering },
            )}
            style={logomarkStyles}
            onMouseEnter={enterNavLogoAction}
            onMouseLeave={leaveNavLogoAction}
          />
        </BlockLink>
        <a.div
          className={classNames(
            'nav-menu-trigger',
            'button',
            { navMenuOpen: navMenu.open && animationReady },
            //{ active: navMenu.triggerHovering && mainSiteOverlay },
            { active: mainSiteOverlay && !navMenu.open },
            //{ active: this.state.menuIconHovering },
          )}
          onMouseEnter={handleNavMenuTriggerMouseEnter}
          onClick={triggerNavMenu}
          onMouseLeave={handleNavMenuTriggerMouseLeave}
          style={menuIconStyles}
        >
          <a.span style={menuIconTopLineStyles} />
          <a.span style={menuIconMiddleLineStyles} />
          <a.span style={menuIconBottomLineStyles} />
        </a.div>
      </a.div>
    </div>
  );
};

export const HeaderMenu = HeaderMenuComponent;
