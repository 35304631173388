import _mapObject from "./mapObject";
export const mapObject = _mapObject;

import _fillArray from "./fillArray";
export const fillArray = _fillArray;

import _usePrevious from "./usePrevious";
export const usePrevious = _usePrevious;

import _useLatest from "./useLatest";
export const useLatest = _useLatest;

import _useInterval from "./useInterval";
export const useInterval = _useInterval;

import _useTimeout from "./useTimeout";
export const useTimeout = _useTimeout;

//export const windowScrollTop = (typeof window !== 'undefined' ? window.scrollY : 0);

export const generateUid = () => ((Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase());

export const hashCode = (value: string) => {
  let hash = 0;
  if (value.length === 0) {
    return hash;
  }
  for (let i = 0; i < value.length; i++) {
    const char = value.charCodeAt(i);
    // tslint:disable-next-line
    hash = ((hash << 5) - hash) + char;
    // tslint:disable-next-line
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash.toString();
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // tslint:disable-next-line
    const r = Math.random() * 16 | 0;
    // tslint:disable-next-line
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export const getPixelRatio = context => {
  const backingStore =
    context.backingStorePixelRatio ||
    context.webkitBackingStorePixelRatio ||
    context.mozBackingStorePixelRatio ||
    context.msBackingStorePixelRatio ||
    context.oBackingStorePixelRatio ||
    context.backingStorePixelRatio ||
    1;

  return (window.devicePixelRatio || 1) / backingStore;
};
