import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTimeout, useLatest } from 'utils/Index';
import { useScrollPosition } from 'hooks/useScrollPosition';
import { useSpring, a } from 'react-spring';

import classNames from 'classnames';

import { Bar } from './Bar';

import * as fromInPageNavigation from 'store/InPageNavigation';

export type IBackgroundShade = 'light' | 'dark';

interface IProps {
  backgroundShade: IBackgroundShade;
}

const InPageNavigation: React.FC<IProps> = ({ backgroundShade = 'dark' as 'dark', ...props }) => {
  const navigationBarArray = useSelector(fromInPageNavigation.getNavigationBarArray);
  const hoveringOverSectionNumber = useSelector(fromInPageNavigation.getHoverOverSectionNumber);
  const showSectionLabels = useSelector(fromInPageNavigation.getShowSectionLabels);
  const latestShowSectionLabels = useLatest(showSectionLabels);

  const scrollY = useScrollPosition();

  const dispatch = useDispatch();

  const updateHoveringSectionNumberAction = React.useCallback(
    (section: number) => dispatch(fromInPageNavigation.actionCreators.updateHoveringSectionNumberAction(section))
    , [dispatch]);

  const showSectionLabelsAction = React.useCallback(
    () => dispatch(fromInPageNavigation.actionCreators.showSectionLabelsAction())
    , [dispatch]);

  const hideSectionLabelsAction = React.useCallback(
    () => dispatch(fromInPageNavigation.actionCreators.hideSectionLabelsAction())
    , [dispatch]);

  const scrollToSectionNumberAction = React.useCallback(
    (section: number) => dispatch(fromInPageNavigation.actionCreators.scrollToSectionNumberAction(section))
    , [dispatch]);

  const [hideLabelsTimeout, setHideLabelsTimeout] = React.useState(false);
  useTimeout(() => {
    setHideLabelsTimeout(false);
    if (latestShowSectionLabels.current)
      hideSectionLabelsAction();
  }, hideLabelsTimeout ? 2000 : null);

  const handleMouseEnter = () => {
    setHideLabelsTimeout(false);
    if (!showSectionLabels)
      showSectionLabelsAction();
  };

  const handleMouseLeave = () => {
    setHideLabelsTimeout(true);
  };

  const updateHoveringSectionNumber = (section: number) => {
    if (hoveringOverSectionNumber !== section) {
      updateHoveringSectionNumberAction(section);
      handleMouseEnter();
    }
  };

  const getBars = () => {
    const bars = [];
    if (navigationBarArray.length > 0) {
      navigationBarArray.map((navSection, i) => {
        bars.push((
          <Bar
            key={i}
            sectionNumber={i + 1}
            currentSection={navSection.currentSection}
            hovering={i + 1 === hoveringOverSectionNumber}
            backgroundShade={backgroundShade}
            targetPosition={navSection.scrollProgress}
            showLabel={showSectionLabels}
            label={navSection.title}
            updateHoveringSectionNumber={updateHoveringSectionNumber}
            onClick={() => scrollTo(i + 1)}
          />
        ));
      });
    }

    return bars;
  };

  const currentSectionNumber = useSelector(fromInPageNavigation.getCurrentSectionNumber);
  const numberOfSections = useSelector(fromInPageNavigation.getTotalSectionsNumber);
  const navigationArray = useSelector(fromInPageNavigation.getNavigationArray);

  const scrollTo = sectionNumber => {
    console.log(sectionNumber, currentSectionNumber, navigationBarArray.length);
    if (sectionNumber > 0 && sectionNumber < navigationBarArray.length + 1)
      scrollToSectionNumberAction(sectionNumber);
  };

  const scrollToPreviousSection = () => { scrollTo(currentSectionNumber - 1); };
  const scrollToNextSection = () => { scrollTo(currentSectionNumber + 1); };

  const showBars = () => {
    if (navigationArray.length > 0) {
      if (((200 + scrollY) > navigationArray[0].scrollTop) && ((200 + scrollY) < (navigationArray[navigationArray.length - 1].scrollTop + navigationArray[navigationArray.length - 1].height)))
        return 1;
    }
    return 0;
  };

  const [animateInSpring, setAnimateInSpring] = useSpring(() => ({ z: showBars() }));

  React.useEffect(() => {
    setAnimateInSpring({ z: showBars() });
  }, [scrollY, navigationArray]);

  const wrapperStyles = {
    opacity: animateInSpring.z,
  };

  return (
    <a.div
      className={classNames(
        'inPageNavigationWrapper',
        'backgroundShade-' + backgroundShade,
      )}
      style={wrapperStyles}
    >
      <div className='sectionNumber'>
        <div className='currentSection'>
          0{currentSectionNumber}
        </div>
        <div className='split' />
        <div className='totalSections'>
          0{numberOfSections}
        </div>
      </div>
      <div className='sectionBarsWrapper' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {getBars()}
      </div>
      <div className='sectionControls'>
        <div className='control previous' onClick={scrollToPreviousSection} />
        <div className='control next' onClick={scrollToNextSection} />
      </div>
    </a.div>
  );
};

export default InPageNavigation;
