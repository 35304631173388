import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as fromInPageNavigation from 'store/InPageNavigation';

import { useScrollTarget } from 'hooks/useScrollTarget';
import { useScrollPosition } from 'hooks/useScrollPosition';
import { usePrevious } from 'utils/Index';
const InPageNavigationProps: React.FC<{}> = () => {

  const dispatch = useDispatch();

  const scrollToSectionNumberAction = React.useCallback(
    (section: number) => dispatch(fromInPageNavigation.actionCreators.scrollToSectionNumberAction(section))
    , [dispatch]);

  const setCurrentSectionNumberAction = React.useCallback(
    (section: number) => dispatch(fromInPageNavigation.actionCreators.setCurrentSectionNumberAction(section))
    , [dispatch]);

  const updateNavigationBarArrayAction = React.useCallback(
    (navigationBarArray: fromInPageNavigation.INavigationBar[]) => dispatch(fromInPageNavigation.actionCreators.updateNavigationBarArrayAction(navigationBarArray))
    , [dispatch]);

  const animateScrollTo = useScrollTarget(true, null, () => scrollToSectionNumberAction(-1));

  const scrollY = useScrollPosition();
  const previousScrollY = usePrevious(scrollY);

  const currentSectionNumber = useSelector(fromInPageNavigation.getCurrentSectionNumber);

  const numberOfSections = useSelector(fromInPageNavigation.getTotalSectionsNumber);

  const navigationArray = useSelector(fromInPageNavigation.getNavigationArray);
  const previousNavigationArray = usePrevious(navigationArray);

  const scrollToSectionNumber = useSelector(fromInPageNavigation.getScrollToSectionNumber);
  const previousScrollToSectionNumber = usePrevious(scrollToSectionNumber);

  React.useEffect(() => {
    if (numberOfSections > 0) {
      if (navigationArray !== previousNavigationArray || scrollY !== previousScrollY) {
        if (navigationArray.length > 0) {
          let newCurrentSectionNumber: number = 1;
          const navigationBarArray: fromInPageNavigation.INavigationBar[] = [];
          navigationArray.map((navSection, i) => {
            const currentNavProps = navSection;
            let scrollProgress = (scrollY - currentNavProps.scrollTop) / currentNavProps.height;

            const currentSection = (scrollProgress >= 0 && scrollProgress < 1);

            if (currentSection || (i + 1 === numberOfSections && scrollProgress > 0))
              newCurrentSectionNumber = i + 1;

            if (scrollProgress < 0)
              scrollProgress = 0;
            else if (scrollProgress > 1)
              scrollProgress = 1;

            navigationBarArray.push({
              title: navSection.title,
              scrollProgress,
              currentSection,
            });
          });
          if (newCurrentSectionNumber !== currentSectionNumber)
            setCurrentSectionNumberAction(newCurrentSectionNumber);

          updateNavigationBarArrayAction(navigationBarArray);
        }
      }
      if (scrollToSectionNumber !== previousScrollToSectionNumber && scrollToSectionNumber !== -1) {

        const finalScrollTop = navigationArray[scrollToSectionNumber - 1].scrollTop + 5;

        animateScrollTo(finalScrollTop);
      }
    }
  }, [numberOfSections, navigationArray, scrollY, currentSectionNumber, scrollToSectionNumber]);

  return null;
};

export default InPageNavigationProps;
