import * as React from 'react';

import { useTimeout, usePrevious } from 'utils/Index';
import { useSpring, a, to } from 'react-spring';

import classNames from 'classnames';

export type IBackgroundShade = 'light' | 'dark';

interface IProps {
  sectionNumber: number;
  currentSection: boolean;
  hovering: boolean;
  backgroundShade: IBackgroundShade;
  targetPosition: number;
  showLabel: boolean;
  label: string;
  updateHoveringSectionNumber(section: number): void;
  onClick(): void;
}

export const Bar: React.FC<IProps> = ({
  sectionNumber,
  currentSection,
  hovering = false,
  backgroundShade = 'dark' as 'dark',
  targetPosition,
  showLabel = false,
  label,
  updateHoveringSectionNumber,
  onClick,
  ...props
}) => {
  const [expand, setExpand] = React.useState(false);

  const [unexpandTimeout, setUnexpandTimeout] = React.useState(false);
  useTimeout(() => {
    setUnexpandTimeout(false);
    setExpand(false);
  }, unexpandTimeout ? 3000 : null);

  const previousTargetPosition = usePrevious(targetPosition);

  React.useEffect(() => {
    if (targetPosition !== previousTargetPosition) {
      setUnexpandTimeout(false);
      if (!expand)
        setExpand(true);
      setUnexpandTimeout(true);
    }
  }, [targetPosition, expand]);

  const handleMouseEnter = () => {
    updateHoveringSectionNumber(sectionNumber);
  };

  const handleMouseLeave = () => {
    updateHoveringSectionNumber(0);
  };

  const handleMouseClick = () => {
    onClick();
  };

  const springValues = (initial = false) => ({
    label: showLabel ? 0.7 : 0,
    active: showLabel && (hovering || currentSection) ? 0.3 : 0,
    expand: (expand || showLabel || (initial && targetPosition > 0)) && currentSection ? 1 : 0,
    percentage: targetPosition,
  });

  const [spring, setSpring] = useSpring(() => (springValues(true)));

  React.useEffect(() => {
    setSpring(springValues());
  }, [showLabel, hovering, currentSection, expand, targetPosition]);

  const wrapperStyles = {
    height: spring.expand.to(x => `calc(30px + (${x} * 270px))`),
  };

  const barStyles = {
    transform: spring.percentage.to(p => `translateY(calc(     (-100%) + (${p} * 100%)     ))`),
  };

  const labelStyles = {
    opacity: to([spring.label, spring.active], (l, ac) => (l + ac)),
  };

  return (
    <a.div
      className='barWrapper'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
      style={wrapperStyles}
    >
      <div className='bar'>
        <div className='background' />
        <a.div
          className={classNames(
            'progressBar',
            { enableShadow: targetPosition > 0 },
          )}
          style={barStyles}
        />
      </div>
      <a.div
        className={classNames(
          'label',
          { activateLink: showLabel },
        )}
        style={labelStyles}
      >
        <div className='text'>
          {label}
        </div>
      </a.div>
    </a.div>
  );
};
